<template>
  <div class="">
    <div class="recommend-header" style="padding: 12px 27px 12px 27px">
      <div class="title">今日热门</div>
      <div class="switch-btn" @click="nextTodayRankPage(null)">
        <i class="iconfont icon-change switch-btn-icon" style="margin-right: 4px"></i>
        换一批
      </div>
    </div>
    <div style="position: relative" v-for="(item,index) in todayRank">
<!--      <div :class="`plan-rank-index plan-rank-index-${index+1}`">{{index+1}}</div>-->
      <rank-item :item="{
        planId: item.plan.id,
        planCover: item.plan.cover,
        planTitle: item.plan.planTitle,
        createAvatar: item.creator.avatar,
        createUsername: item.creator.nickName,
        rankIndex: item.rankIndex
      }"/>
<!--      <plan-card @handle-click="goPlanDetail(item)" :detail="item"></plan-card>-->
    </div>
  </div>
</template>

<script>
import vCase from '@/components/case.vue'
import {planV2Api} from "../../../../api/v2/planV2Api";
import {nav} from "../../../../config/nav";
import PlanCard from "../../../../components/planCard";
import RankItem from "../../../home/components/rankItem";


export default {
  props: ['info'],
  components: {RankItem, PlanCard, vCase},
  data() {
    return {
      todayRank: [],
      allTodayRank: [],
      todayRankCurrent: 1
    }
  },


  async mounted() {
    await this.loadTodayRank()
    this.nextTodayRankPage(1)
  },


  methods: {
    async loadTodayRank() {
      const res = await planV2Api.findTodayRank()
      for(let i = 0;i<res.length;i++){
        res[i].rankIndex = i + 1
      }
      this.allTodayRank = res
    },


    nextTodayRankPage(current) {
      const size = 10
      const maxCurrent = Math.ceil(this.allTodayRank.length / size)
      current = current || this.todayRankCurrent + 1
      if (current < 1 || current > maxCurrent) {
        current = 1
      }
      const startIndex = (current - 1) * size
      this.todayRank = this.allTodayRank.slice(startIndex, startIndex + size)
      this.todayRankCurrent = current
    },


    goPlanDetail(item) {
      nav.goPlanDetail(item.plan.id)
    }
  }

}
</script>

<style scoped>
@import "./planDetailRefs.css";

.plan-rank-index{
  position: absolute;
  right: 5px;
  top: 5px;
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: 4px solid #151d36;
  font-size: 14px;
  font-style: italic;
  font-weight: bolder;
}

.plan-rank-index-1{
  border-color: #e44c4c;
}
.plan-rank-index-2{
  border-color: #fc744c;
}
.plan-rank-index-3{
  border-color: #fcc44c;
}

</style>
