<template>
  <div>
    <confirm ref="deleteHistoryConfirm" @confirm="deleteHistory"/>
    <van-popup v-model="visible" position="top">
      <div class="home-search home-list" style="height: 80vh">
        <div :style="{height:headerHeight+'px'}"></div>
        <van-search @focus="onFocus" ref="searchInput" placeholder="搜方案" v-model="searchValue"
                    @search="onSearch"/>
        <div class="tip">多个关键词以逗号或空格隔开</div>

        <div class="search-history" v-if="keywordArr && keywordArr.length">
          <div class="search-history-header">
            <div class="search-history-title">正在搜索</div>
            <van-icon name="cross" @click="setKeyword('')"/>
          </div>
          <div class="history-tags keywords">
            <div class="keyword" v-for="(item,index) in keywordArr" :key="index" @click="removeKeyword(item)">
              {{ item }}
              <van-icon name="cross"/>
            </div>
          </div>
        </div>

        <div class="search-history" v-if="histories && histories.length">
          <div class="search-history-header"  @click="visibles.allHistory=!visibles.allHistory">
            <div class="search-history-title">最近搜索</div>
            <div style="display: flex;align-items: center">
              <van-icon name="delete-o" style="margin-left: 8px" @click.stop="showDeleteHistoryConfirm"/>
              <div class="expand-btn" :class="{expand: visibles.allHistory}" v-if="needAllHistoryBtn">
                <van-icon name="arrow"></van-icon>
              </div>
            </div>
          </div>
          <div class="history-tags keywords" ref="historyKeywords" :class="{expand: visibles.allHistory}">
            <div class="keyword" @click="setKeyword(item)" v-for="item in histories" :key="item.id">
              {{ item }}
            </div>
          </div>
        </div>


        <div class="search-history" v-if="brands && brands.length">
          <div class="search-history-header"  @click="visibles.allBrand=!visibles.allBrand">
            <div class="search-history-title">大厂同款</div>
            <div class="expand-btn" :class="{expand: visibles.allBrand}">
              <van-icon name="arrow"></van-icon>
            </div>
          </div>
          <div class="history-tags keywords" :class="{expand: visibles.allBrand}">
            <div class="keyword" v-for="(item,index) in brands" :key="index" @click="searchBrank(item)">
              {{item.name}} <template v-if="!item.url && item.refPlanCount > 0">({{item.refPlanCount}}份)</template>
            </div>
          </div>
        </div>

        <div class="search-history" v-if="searchLinks && searchLinks.length">
          <div class="search-history-header"  @click="visibles.allSearchLinks=!visibles.allSearchLinks">
            <div class="search-history-title">热门合集</div>
            <div class="expand-btn" :class="{expand: visibles.allSearchLinks}">
              <van-icon name="arrow"></van-icon>
            </div>
          </div>
          <div class="history-tags keywords" :class="{expand: visibles.allSearchLinks}">
            <div class="keyword" v-for="(item,index) in searchLinks" :key="index" @click="goSearchLink(item)">
              {{item.name}} <template v-if="item.planCount">({{item.planCount}}份)</template>
            </div>
          </div>
        </div>


        <div class="search-history">
          <div class="search-history-header">
            <div class="search-history-title">今日热门</div>
            <a href="/home?tab=rank" style="display: flex;align-items: center">查看完整版
              <van-icon name="arrow"/>
            </a>
          </div>
          <div class="ranks">

            <div class="rank rank-keyword">
              <div class="rank-title">活动方案</div>
              <template
                v-if="!getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_HUO_DONG,days.planTypeFree).length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>

              <template v-else>
                <div @click="setKeywordAndExactOnly(item.planTitle)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_HUO_DONG,days.planTypeFree)"
                     :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item.planTitle }}</div>
                </div>
              </template>
            </div>


            <div class="rank rank-brank">
              <div class="rank-title">营销方案</div>

              <template
                v-if="!getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_YING_XIAO,days.planTypeFree).length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>
              <template v-else>
                <div @click="setKeywordAndExactOnly(item.planTitle)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_YING_XIAO,days.planTypeFree)"
                     :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item.planTitle }}</div>
                </div>
              </template>
            </div>


            <div class="rank rank-plan-type">
              <div class="rank-title">合作方案</div>

              <template
                v-if="!getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_DUI_WAI,days.planTypeFree).length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>
              <template v-else>
                <div @click="setKeywordAndExactOnly(item.planTitle)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_DUI_WAI,days.planTypeFree)"
                     :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item.planTitle }}</div>
                </div>
              </template>
            </div>


            <div class="rank rank-industry">
              <div class="rank-title">运营/设计方案</div>

              <template
                v-if="!getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_SHE_JI_YUN_YING,days.planTypeFree).length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>
              <template v-else>
                <div @click="setKeywordAndExactOnly(item.planTitle)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_SHE_JI_YUN_YING,days.planTypeFree)"
                     :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item.planTitle }}</div>
                </div>
              </template>
            </div>


            <div class="rank rank-brank">
              <div class="rank-title">课件/研报/其他</div>

              <template
                v-if="!getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_SHE_JI_YUN_YING,days.planTypeFree).length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>
              <template v-else>
                <div @click="setKeywordAndExactOnly(item.planTitle)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in getPlanRank(planRankTypeEnum.FREE,planRankRangeTypeEnum.PLAN_TYPE_SHE_JI_YUN_YING,days.planTypeFree)"
                     :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item.planTitle }}</div>
                </div>
              </template>
            </div>


            <div class="rank rank-keyword">
              <div class="rank-title">内容热搜</div>

              <template v-if="!ranks.keywords || !ranks.keywords.length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>
              <template v-else>
                <div @click="setKeywordAndExactOnly(item)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in ranks.keywords" :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item }}</div>
                </div>
              </template>
            </div>

            <div class="rank rank-brank">
              <div class="rank-title">品牌热搜</div>

              <template v-if="!ranks.branks || !ranks.branks.length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>
              <template v-else>
                <div @click="setKeywordAndExactOnly(item)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in ranks.branks" :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item }}</div>
                </div>
              </template>
            </div>


            <div class="rank rank-plan-type">
              <div class="rank-title">类型热搜</div>

              <template v-if="!ranks.planTypes || !ranks.planTypes.length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>
              <template v-else>
                <div @click="setKeywordAndExactOnly(item)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in ranks.planTypes" :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item }}</div>
                </div>
              </template>
            </div>

            <div class="rank rank-industry" style="margin-right: 0">
              <div class="rank-title">行业热搜</div>

              <template v-if="!ranks.industryTypes || !ranks.industryTypes.length">
                <div class="rank-item" v-for="i in 5" :key="i">
                  <div class="skeleton-block" style="width: 100%;height: 22px"></div>
                </div>
              </template>
              <template v-else>
                <div @click="setKeywordAndExactOnly(item)" :class="'rank-item rank-item-'+(index+1)"
                     v-for="(item,index) in ranks.industryTypes" :key="index">
                  <div class="rank-index">{{ index + 1 }}</div>
                  <div class="title">{{ item }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="close-btn" @click="visible=false">
          <van-icon name="cross"/>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {searchRecordV2Api} from "../../../api/v2/searchRecordV2Api";
import {searchHistoryV2Api} from "../../../api/v2/searchHistoryV2Api";
import {sleep} from "../../../config/util";
import {planRankV2Api} from "../../../api/v2/planRankV2Api";
import {planRankTypeEnum, planRankDaysEnum, planRankRangeTypeEnum} from "../../../config/enums";
import Confirm from "../../../components/confirm";
import {searchLinkV2Api} from "../../../api/v2/searchLinkV2Api";
import {brandV2Api} from "../../../api/v2/brandV2Api";

export default {
  components: {Confirm},
  props: ['headerHeight', 'query'],
  watch: {
    'query.keyword': {
      immediate: true,
      handler(val) {
        this.searchValue = val
      }
    },

    async visible() {
      if (this.visible) {
        await sleep(200)
        this.$refs.searchInput.querySelector('input').focus()
      }
    }
  },
  computed: {

    keywordArr() {
      if (this.query.keyword) {
        return this.query.keyword.split(',').filter(k => k && k.length)
      }
      return []
    },

  },

  data() {
    return {
      searchValue: '',
      visible: false,
      hasInit: false,
      visibles:{
        allBrand: false,
        allSearchLinks: false,
        allHistory: false
      },
      needAllHistoryBtn: false,
      ranks: {
        keywords: [],
        branks: [],
        industryTypes: [],
        planTypes: []
      },
      histories: [],
      allPlanRank: [],
      searchLinks: [],
      brands: [],
      planRankTypeEnum, planRankDaysEnum, planRankRangeTypeEnum,
      days: {
        planTypeFree: 1,
        industryTypeFree: 1,
        planTypePay: 1,
        industryTypePay: 1
      },
    }
  },

  methods: {

    goSearchLink(item){
      let url = ''
      if(item.url.startsWith('https')){
        url = item.url
      }else{
        url = `https://www.001ppt.com`+item.url
      }
      window.location.href = url
    },

    searchBrank(item){
      if(item.url && item.url.length){
        return window.location.href = item.url
      }
      this.$emit('searchBrank',item.name)
      this.visible = false
    },

    getPlanRank(rankType, rangeType, days) {
      return this.allPlanRank.filter((r) =>
        r.rankType === rankType &&
        r.days === days &&
        r.rangeType === rangeType
      ).slice(0, 5)
    },

    async loadPlanRank() {
      this.allPlanRank = await planRankV2Api.findAll()
    },


    toggleSearch() {
      this.visible = !this.visible
      if (this.visible && !this.hasInit) {
        this.loadRank()
        this.loadPlanRank()
        this.loadSearchLink()
        this.loadBrand()
        this.loadSearchHistory()
        this.hasInit = true
      }
    },


    async loadBrand(){
      this.brands = await brandV2Api.randomGet()
    },


    async loadSearchLink(){
      this.searchLinks = await searchLinkV2Api.randomGet()
    },


    async loadRank() {
      this.ranks = await searchRecordV2Api.findRank()
    },


    async loadSearchHistory() {
      const res = await searchHistoryV2Api.findMyHistoryKeywords()
      this.histories = res.planKeywords
      await sleep(100)
      this.needAllHistoryBtn = this.$refs.historyKeywords.clientHeight !== this.$refs.historyKeywords.scrollHeight
    },


    onSearch() {
      const keywordArr = this.searchValue
        .replace(/，/g, ',')
        .replace(/\s/g, ',')
        .split(',')
        .filter(k => k && k.length)
      const keyword = keywordArr.join(',')
      this.visible = false
      this.$emit('setKeyword', keyword)
      // if (this.searchValue && this.searchValue.length) {
      //   searchHistoryV2Api.add(keyword, 1)
      // }
    },


    removeKeyword(item) {
      this.$emit('removeKeyword', item)
      this.visible = false
    },


    setKeyword(item) {
      this.$emit('setKeyword', item)
      this.addHistory(item)
      this.visible = false
    },


    setKeywordAndExactOnly(item) {
      this.$emit('setKeywordAndExactOnly', item)
      this.addHistory(item)
      this.visible = false
    },


    showDeleteHistoryConfirm() {
      this.$refs.deleteHistoryConfirm.show({
        type: 'warning',
        title: '删除搜索记录',
        content: '该操作将删除所有搜索记录，请确认'
      })
    },

    // 删除搜索历史
    async deleteHistory() {
      await searchHistoryV2Api.deleteMy('plan')
      this.histories = []
      this.$toast.success('删除成功')
    },


    async addHistory(item) {
      const exists = this.histories.some(h => h === item)
      if (!exists) {
        // await searchHistoryV2Api.add(item, 1)
        // const h = {
        //   id: Date.now(),
        //   keyword: item,
        //   targetType: 1
        // }
        // this.histories = [h, ...this.histories]
        this.histories = [item, ...this.histories]
      }
    },

    hide() {
      this.visible = false
    },
    show() {
      this.visible = true
      this.$refs.searchInput.focus()
    },

    onFocus() {
      this.$refs.searchInput.querySelector('input').select()
    }


  }
}
</script>

<style scoped lang="less">
@import "home-list";

.home-search {
  background: #fff;
  position: relative;
}

.ranks {
  padding-bottom: 12px;
  display: flex;
  align-items: flex-start;
  width: calc(100vw - 54px);
  overflow-x: auto;
}

.rank {
  margin-top: 12px;
  margin-right: 24px;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
}

.rank-title {
  font-weight: bolder;
  padding: 12px;
  color: #fff;
}

.rank-item {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: calc(22vw + 32px);
}

.rank-index {
  font-weight: bolder;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.rank-item-1 .rank-index {
  color: #e44c4c;
}


.rank-item-2 .rank-index {
  color: #fc744c;
}

.rank-item-3 .rank-index {
  color: #fcc44c;
}

.rank-item .title {
  width: 22vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-history {
  margin-top: 24px;
}

.search-history-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-history-title {
  font-weight: bolder;
}

.history-tags {
  padding: 12px 0 !important;
  border-bottom: none !important;
}

.rank-keyword {
  border-color: #e44c4c;
}

.rank-keyword .rank-title {
  background: #e44c4c;
}

.rank-brank {
  border-color: #fc744c;
}

.rank-brank .rank-title {
  background: #fc744c;
}

.rank-plan-type {
  border-color: #fcc44c;
}

.rank-plan-type .rank-title {
  background: #fcc44c;
}

.rank-industry {
  border-color: #151D36;
}

.rank-industry .rank-title {
  background: #151D36;
}

.close-btn {
  height: 50px;
  width: 50px;
  background: #f4f4f4;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 70vh;
}

.tip {
  color: #999;
  padding-top: 8px;
  font-size: 12px;

  &:before {
    content: '*';
    color: #e44c4c;
    font-size: 14px;
    padding-right: 4px;
  }
}


.expand-btn{
  transform: rotateZ(90deg);
  transition: transform .2s ease-in;
}

.expand-btn.expand{
  transform: rotateZ(90deg) rotateY(180deg);
}

.keywords{
  max-height: 72px;
  overflow-y: hidden;
}
.keywords.expand{
  max-height: 100000px;
}


</style>
