import {userV2Api} from "../api/v2/userV2Api";

export const vipInfoMixin = {
  data() {
    return {
      vipInfo: {
        vipRightUserId: '',
        vipRightId: '3',    // 会员权益id
        expireTime: '',     // 会员超时时间
        lastExpireVipRightId: '',  // 已超时的会员id
        lastExpireTime: '',        // 超时会员的超时时间
        freeCase: 0,               // 用户免费解锁总额度
        remainFreeCase: 0,         // 剩余的可解锁免费方案数量
        vipFreeCase: 0,            // 用户会员免费解锁的总额度
        remainVipFreeCase: 0,      // 剩余的可解锁会员方案数量
        vipDiscount: 0,            // 购买会员折扣的折扣
        superVipDiscount: 0,       // 超级会员购买会员折扣的折扣
        vipDiscountOnPlanList: 0,  // 解锁合集的折扣
        lastNotifyJoinTime: null,  // 最后一次通知入会时间
        lastNotifyExpireTime: null // 最后一次通知过期时间
      }
    }
  },


  created() {
    this.loadMyVip().then();
  },


  methods: {
    async loadMyVip() {
      this.vipInfo = await userV2Api.findMyVip()
    }
  }
}
