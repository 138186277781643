import {v2Request} from "./v2Request";


export const searchLinkV2Api = {

  randomGet(){
    return v2Request.get('/search-svr/searchLink/randomGet')
  }

}
