<template>
  <div class="plan-detail-img-list">
    <template v-if="!loading">
      <div class="plan-img-box" v-for="(item,index) in images" :key="index">
        <template v-show="firstLoaded">
          <van-image v-if="index===0 && info.downloadPrice === 0" class="price-type-img" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/plan-free.svg" alt="">
            <template v-slot:loading><div style="background-color: #efebeb"></div></template>
          </van-image>
          <van-image v-if="index===0 && info.downloadPrice !== 0 && info.isMemberFree === 'Y'" class="price-type-img" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/plan-vip-free.svg" alt="">
            <template v-slot:loading><div style="background-color: #efebeb"></div></template>
          </van-image>
          <van-image v-if="index===0 && info.downloadPrice !== 0 && info.isMemberFree === 'N'" class="price-type-img" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/plan-need-pay.svg" alt="">
            <template v-slot:loading><div style="background-color: #efebeb"></div></template>
          </van-image>
        </template>

        <van-image class="plan-img" @click="preview(index)" lazy-load :src="item.picFullPath" @load="onImageLoad(index)">
          <template v-slot:loading>
            <div style="width: calc(100vw - 16px);height: calc((100vw - 16px) * 9 / 16);" class="skeleton-block">
            </div>
          </template>
        </van-image>
      </div>

      <div class="plan-img swipe-end" v-if="info && info.projPlanPicResources && !info.pay">
        <div class="swiper-end-thumb">
          <img :src="info.thumbUrl" alt="">
        </div>

        <div style="z-index: 3;display: flex;flex-direction: column;align-items: center">
          <van-image v-if="info.downloadPrice === 0" class="price-type-img" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/plan-free.svg" alt=""/>
          <van-image v-if="info.downloadPrice !== 0 && info.isMemberFree === 'Y'" class="price-type-img" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/plan-vip-free.svg" alt=""/>
          <van-image v-if="info.downloadPrice !== 0 && info.isMemberFree === 'N'" class="price-type-img" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/plan-need-pay.svg" alt=""/>

          <img class="white_logo" src="https://cdn.001ppt.cn/h5/assets/images/white-logo.png" width="200" alt="">
          <div style="color: #fff;font-size: 12px;margin-top: 5px;">
            本方案共{{ info.uploadResourcePage}}页，已免费看完{{ info.supportLookFree }}页
          </div>
          <div style="color: #fff;font-size: 12px;margin-top: 5px;">点击右下角按钮解锁完整版</div>
        </div>
      </div>

      <van-image-preview
        ref="preview"
        @change="changeImg"
        v-model="visible"
        :start-position="startPosition"
        :loop="false"
        :images="images.map(i => i.picFullPath)">
        <template slot="index">
          <div class="trunk-group">
            <div class="location">
              <span>{{ current + 1 }}/{{ images.length }}</span>
            </div>
            <div class="trunk">
              <div class="trunk-scroll" :style="{width: `${images.length*90+(images.length-1)*8}px`}">
                <template v-for="(i, index) in images">
                  <img
                    @click="preview(index)"
                    v-lazy="i.picFullPath"
                    style=";width: 90px"
                    width="90"
                    :style="{marginLeft: index === 0 ? '0': '8px'}">
                </template>
              </div>
            </div>
          </div>
        </template>
      </van-image-preview>
    </template>

    <template v-else>
      <div class="skeleton-block"
           style="width: calc(100vw - 16px);margin: 0 auto 4px auto;height: calc( (100vw - 16px) * 9 / 16 )"></div>
      <div class="skeleton-block"
           style="width: calc(100vw - 16px);margin: 0 auto 4px auto;height: calc( (100vw - 16px) * 9 / 16 )"></div>
      <div class="skeleton-block"
           style="width: calc(100vw - 16px);margin: 0 auto 4px auto;height: calc( (100vw - 16px) * 9 / 16 )"></div>
      <div class="skeleton-block"
           style="width: calc(100vw - 16px);margin: 0 auto 4px auto;height: calc( (100vw - 16px) * 9 / 16 )"></div>
      <div class="skeleton-block"
           style="width: calc(100vw - 16px);margin: 0 auto 4px auto;height: calc( (100vw - 16px) * 9 / 16 )"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['images', 'info', 'loading'],
  data() {
    return {
      visible: false,
      startPosition: 0,
      current: 0,
      firstLoaded: false
    }
  },

  methods: {

    onImageLoad(index){
      if(index === 0){
        this.firstLoaded = true
      }
    },

    changeImg(index) {
      this.current = index
      this.$forceUpdate()
    },

    preview(index) {
      this.startPosition = index
      this.current = index
      this.visible = true
    },
  }
}
</script>

<style scoped lang="less">
.plan-img {
  margin: 0 8px 4px 8px;
  width: calc(100vw - 16px);
  /*min-height: calc((100vw - 16px) * 9 / 16);*/
  display: flex;
  align-items: center;
}

.plan-img-box{
  position: relative;
  //width: calc(100vw - 16px);
  overflow: hidden;
}

.price-type-img{
  height: 48px;
  width: 100px;
  left: 8px;
  top: 0;
  position: absolute;
  z-index: 5;
}

.trunk-group {
  position: relative;
  top: calc(100vh - 110px);
  background-color: #000;
  width: 100vw;
  height: 60px;
  z-index: 2005;
  display: flex;
  flex-direction: column;

  .location {
    position: absolute;
    top: -25px;
    left: 28px;
    padding-left: 5px;
    padding-right: 5px;
    height: 15px;
    border-radius: 15px;
    background-color: #999;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 12px;
      color: #fff;
      opacity: .5;
    }
  }

  .trunk {
    overflow-x: auto;
    margin-left: 28px;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important
    }

    .trunk-scroll {
      img {
        width: 90px;
      }

    }
  }
}


.swipe-end {
  width: calc(100vw - 16px);
  height: calc((100vw - 16px) * 9 / 16);
  //background: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .swiper-end-thumb{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    img{
      width: calc(100vw - 16px);
      height: calc((100vw - 16px) * 9 / 16);
      object-fit: fill;
    }
  }

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #151D36;
    opacity: .8;
    z-index: 2;
  }

  .price-type-img{
    left: 0;
  }

  img {
    width: 200px;
  }

  span {
    color: #fff;
    font-size: 12px;
    margin-top: 5px;
  }
}

.plan-detail-img-list{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
