<template>
  <div class="plan-detail-be-share-guild" v-if="visible">
    <div class="shade" @click.stop="visible=false"></div>
    <div class="content">
      <div>@新朋友</div>
      <div>点击首次登录</div>
      <div>本份免费下载</div>
      <div class="has-know-btn" @click.stop="visible=false">知道了</div>
      <img src="https://cdn.001ppt.cn/h5/static/image/guide-arrow.png" class="guide-arrow-img" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props:['info'],

  data() {
    return {
      visible: false
    }
  },

  watch: {
    visible() {
      if (!this.visible) {
        let userGuide = localStorage.getItem('userGuide') || '{}'
        userGuide = JSON.parse(userGuide)
        userGuide.planDetailBeShare = true
        localStorage.setItem('userGuide', JSON.stringify(userGuide))
      }
    },

    info:{
      immediate: true,
      handler(){
        this.tryShow()
      }
    }
  },


  methods:{
    tryShow(){
      if(!this.info || !this.info.id || (this.info.downloadPrice > 0 && this.info.isMemberFree !== 'Y')){
        return
      }
      if (!this.$route.query.shareUserId) {
        return
      }
      let userGuide = localStorage.getItem('userGuide') || '{}'
      userGuide = JSON.parse(userGuide)
      if (!userGuide) {
        userGuide = {
          planDetailBeShare: false
        }
      }
      if (!userGuide.planDetailBeShare) {
        this.visible = true
      }
    }
  }

}
</script>

<style scoped lang="less">
.plan-detail-be-share-guild {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10000;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.shade {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #151D36;
  opacity: .6;
  z-index: -1;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-size: 16px;
  margin-right: 40px;
  margin-bottom: 100px;
}

.has-know-btn {
  padding: 0 8px;
  border: 1px solid #fff;
  margin-top: 8px;
}

.guide-arrow-img {
  transform: rotate(90deg);
  height: 44px;
  width: 129px;
  margin-top: 40px;
  margin-left: 40px;
}
</style>
