<template>
  <div class="plan-detail-info">
    <template v-if="!loading">
      <div class="title">
        <img v-if="info.goodsRecommend==='Y'" src="https://cdn.001ppt.cn/pc2/static/plan-detail/high-quality-complete.svg" style="height: 14px;width: calc(14px * 300 / 129);margin-right: 8px" alt="">
        <img v-if="info.uploadResourceRemark === 'pdf' || info.uploadResourceRemark === 'PDF'"
             src="https://lgyx-static.oss-cn-guangzhou.aliyuncs.com/pc2/static/imgs/svg/PDF.svg"
             style="height: 20px;width: 20px;transform: translateY(4px)" alt="">
        <img v-else src="https://lgyx-static.oss-cn-guangzhou.aliyuncs.com/pc2/static/imgs/svg/PPT.svg"
             style="height: 20px;width: 20px;transform: translateY(4px)" alt="">
        {{ info.planTitle }}
      </div>
      <div style="display: flex;align-items: center;justify-content: space-between;height: 24px;">
        <div class="creator">
          <img style="border-radius: 100px;border: 1px solid #e9e9e9" @click="goUser" class="avatar"
               :src="info.planUserPhotoResourceUrl || 'https://cdn.001ppt.cn/h5/static/lazy/avatar.png'">
          <div @click="goUser" class="nickName">{{ formatUsername(info.planUsername) }}</div>
          <div class="user-level">LV.{{ info.userLevel || 1 }}</div>
          <div class="size" v-if="visibleOption.fileSizeVisible">{{ setFileSize(info.uploadResourceFileSize) }}</div>
          <!--          <div class="ext line" v-if="visibleOption.fileExtVisible">{{ fileExtFilter(info.uploadResourceRemark) }}</div>-->
          <div class="download-num line" v-if="info.downloadAmount>0 && visibleOption.downloadTimesVisible">
            <van-icon name="back-top" style="transform: rotate(180deg)"/>
            {{ info.downloadAmount || 0 }}
          </div>
          <div class="download-num line">
            <img class="fire-icon" src="https://cdn.001ppt.cn/h5/img/fire.svg">
            {{ fansNumFilter(info.hotScore) }}
          </div>
        </div>

        <!--        <template>-->
        <!--        <div class="price-type free" v-if="info.downloadPrice === 0">免费方案</div>-->
        <!--        <div class="price-type vip-free" v-else-if="info.isMemberFree === 'Y'">会员免费</div>-->
        <!--        <div class="price-type need-pay" v-else>会员折扣</div>-->
        <!--        </template>-->
      </div>
    </template>

    <template v-else>
      <div class="title skeleton-block" style="height: 30px"></div>
      <div class="creator">
        <div class="avatar skeleton-block"></div>
        <div class="skeleton-block" style="flex-grow: 1;height: 30px;margin-left: 8px"></div>
      </div>
    </template>

    <div style="display: flex;padding-top: 12px">
      <plan-rank-badge :rank-info="rankInfo"/>
    </div>
  </div>
</template>

<script>
import PlanRankBadge from "../../../../components/planRankBadge";
import {planV2Api} from "../../../../api/v2/planV2Api";

export default {
  components: {PlanRankBadge},
  props: ['info', 'visibleOption', 'loading'],
  watch: {
    'info.id'() {
      this.loadRankInfo()
    }
  },


  data() {
    return {
      rankInfo: {}
    }
  },


  methods: {
    goUser() {
      this.$router.push(`/otherPerson/${this.info.createId}`)
    },


    async loadRankInfo() {
      this.rankInfo = await planV2Api.findRankInfo(this.info.id)
    }
  }

}
</script>

<style scoped lang="less">
.plan-detail-info {
  padding: 12px 27px;
  background: #fff;
}

.plan-detail-info .title {
  font-weight: bolder;
  font-size: 18px;
}

.creator {
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.avatar {
  height: 24px;
  width: 24px;
  border-radius: 100px;
}

.nickName {
  font-size: 14px;
  font-weight: bolder;
  padding: 0 4px;
}

.user-level {
  font-size: 12px;
  padding: 2px 6px;
  background-color: #151d36;
  color: #ffe19f;
  border-radius: 100px;
  margin-right: 4px;
}

.size, .ext, .download-num {
  font-size: 12px;
  color: #999;
}

.line:before {
  content: '|';
  display: inline-block;
  padding: 0 4px;
  color: #999;
}

.price-type {
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 100px;
  text-align: center;
  transform: scale(.8);
}

.price-type.free {
  background: grey;
  color: #fff;
}

.price-type.vip-free {
  background: #fff1c6;
  color: rgba(21, 29, 54, 1);
}

.price-type.need-pay {
  background: #151D36;
  color: #fff;
}

.fire-icon {
  height: 12px;
  width: 12px;
  transform: translateY(2px)
}
</style>
