<template>
  <router-link to="/hotRank?subTab=planType" class="plan-rank-badge" v-if="rankInfo &&rankInfo.rankIndex>0">
    <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/user-rank-top-icon.svg" alt="">
    {{ rankDesc }}
    <van-icon name="arrow"/>
  </router-link>
  <div style="height: 20px" v-else></div>
</template>


--------------------------- script ---------------------------

<script>
const planRankTypeEnum = {
  FREE: 1,
  PAY: 2
}

export default {

  props: {
    rankInfo: {
      type: Object,
      required: true
    }
  },

  computed: {
    rankDesc() {
      if (!this.rankInfo) {
        return ''
      }

      let rankTypeName;
      let rankDaysName;

      switch (this.rankInfo.rankType) {
        case planRankTypeEnum.PAY:
          rankTypeName = '会员折扣榜';
          break;
        case planRankTypeEnum.FREE:
          rankTypeName = '会员免费榜';
          break
      }

      switch (this.rankInfo.days) {
        case 1:
          rankDaysName = '日榜';
          break;
        case 7:
          rankDaysName = '周榜';
          break;
        case 30:
          rankDaysName = '月榜';
          break;
      }

      return `${rankTypeName}${rankDaysName} TOP${this.rankInfo.rankIndex}`
    }
  },

};
</script>


--------------------------- style -------------------------------

<style scoped lang='less'>
.plan-rank-badge {
  background: #f8edd5;
  color: #f5c164;
  padding: 4px 16px;
  font-size: 12px;
  margin-right: 8px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }
}
</style>
