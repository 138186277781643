<template>
  <model ref="model">
    <div class="unlock-dialog-header">
      <div class="vip-badge" :class="`vip-badge-${vipInfo.vipRightId}`" v-if="vipInfo.vipDiscount && vipInfo.vipDiscount<10">
        <img :src="getVipIcon(vipInfo.vipRightId)">
        <div>会员{{ vipInfo.vipDiscount }}折</div>
      </div>
      <div class="plan-discount" v-if="plan.isInDiscount">限时{{ plan.discountVal }}折</div>
    </div>

    <div class="unlock-dialog">
      <div class="title">
        确认使用&nbsp
        <div class="red">¥{{ finalPrice }}</div>
        &nbsp;解锁
      </div>
      <div class="desc gray">
        严值剩余{{ yanzhiFilter(wallet.totalRemainValue) }}(¥{{ moneyFilter(wallet.totalRemainValue / 10) }})
      </div>
      <unlock-recharge :need-recharge-money="deltaYanzhi/10" :default-recharge-money="100" @paySuccess="unlock" v-if="deltaYanzhi>0"/>



      <div class="confirm-btn" @click="unlock" v-if="deltaYanzhi<=0">确认解锁下载</div>
      <!--      <bind-email @confirm="unlock" v-if="deltaYanzhi<=0"/>-->
      <!--      <bind-email @confirm="rechargeUnlock" v-else title="确认充值解锁"/>-->
      <div class="extra">*确认解锁后，严值将自动抵扣</div>
      <div style="display: flex;align-items: center;justify-content: space-around">
        <div class="link" @click="share">
          🎁 分享好友免费解锁
          <van-icon name="arrow"/>
        </div>
        <div v-if="vipInfo.vipRightId==='3'" class="link" @click="showVipPopup">
          <img src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg">
          {{ vipInfo.superVipDiscount }}折解锁了解一下
          <van-icon name="arrow"/>
        </div>
      </div>
    </div>
  </model>
</template>


------------------ script ------------------
<script>
import Model from "../../../components/model/model";
import BindEmail from "./bindEmail";
import {unlockMixin} from "./unlockMixin";
import UnlockRecharge from "./unlockRecharge";

export default {
  components: {UnlockRecharge, BindEmail, Model},
  mixins: [unlockMixin],
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "./unlock";
</style>
