<template>
  <div>
    <div class="rpxBorder"></div>
    <template v-if="!userInfo.email || visibles.bindEmail">
      <div class="desc">绑定邮箱</div>
      <div class="input-box">
        <input v-model="model.email" placeholder="请输入邮箱">
      </div>
      <div class="input-box">
        <input v-model="model.verifyCode" :maxlength="6" placeholder="请输入邮箱验证码">
        <div class="v-split"></div>
        <div class="verify-input">
          <div v-if="seconds<=0" @click="sendVerifyCode">获取验证码</div>
          <div v-else>{{ seconds }}后重新获取</div>
        </div>
      </div>
      <div class="confirm-btn" @click="submitBindEmail">确认绑定邮箱</div>
      <div class="cancel-btn" v-if="userInfo.email" @click="visibles.bindEmail=false">取消修改</div>
    </template>
    <template v-else>
      <div class="desc">接收邮箱</div>
      <div class="email">{{ userInfo.email }}
        <van-icon name="edit" @click="visibles.bindEmail=true"/>
      </div>
      <div class="confirm-btn" :class="disabled?'disabled':''" @click="confirm">{{ title || '确认发送至邮箱' }}</div>
    </template>
  </div>
</template>


------------------ script ------------------
<script>
import Model from "../../../components/model/model";
import {mapGetters, mapMutations} from "vuex";
import {user, scheme} from '@/api'
import {sleep} from "../../../config/util";

export default {
  components: {Model},
  computed: {
    ...mapGetters(['userInfo'])
  },
  props: {
    planId: {
      type: String
    },
    title: {
      type: String
    },
    disabled:{
      type:Boolean
    }
  },

  data() {
    return {
      model: {
        email: '',
        verifyCode: ''
      },
      seconds: 0,
      visibles: {
        bindEmail: false
      }
    }
  },


  methods: {
    ...mapMutations(['SET_USERINFO']),
    toggleDialog() {
      this.$refs.model.toggleDialog();
    },


    // 发送验证码
    async sendVerifyCode() {
      if (!this.model.email) {
        return this.$toast.info('请输入邮箱');
      }
      await user.emailCode(this.model.email);
      this.$toast.success('发送成功');
      this.seconds = 60;
      while (this.seconds > 0) {
        await sleep(1000);
        this.seconds--;
      }
    },


    // 提交绑定邮箱
    async submitBindEmail() {
      if (!this.model.email) {
        return this.$toast.info('请输入邮箱');
      }
      if (!this.model.verifyCode) {
        return this.$toast.info('请输入验证码');
      }
      const res = await user.email({email: this.model.email, emailValidateCode: this.model.verifyCode});
      if (res.code != 1) {
        return this.$toast.info(res.msg);
      }

      const userInfo = {
        ...this.userInfo,
        email: this.model.email
      }
      this.visibles.bindEmail = false;
      this.model.email = '';
      this.model.verifyCode = '';
      this.seconds = 0;
      this.SET_USERINFO(userInfo);
    },


    async confirm() {
      this.$emit('confirm')
    }

  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "unlock";

.rpxBorder {
  background-color: rgba(21, 29, 54, 0.03);
  width: 100%;
  height: 1px;
}

.input-box {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 40px;
  background: #f3f3f5;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 12px;

  input {
    flex-grow: 1;
    border: none;
    background: #f3f3f5;
  }

  .v-split {
    height: 16px;
    width: 1px;
    background: #8a8e9a;
    border-radius: 100px;
    margin: 0 12px;
    transform: scaleX()(.5);
    flex-shrink: 0;
  }

  .verify-input {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.confirm-btn {
  background: #151D36;
  color: #fff;
  width: 100%;
  margin-top: 16px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;

  &.disabled{
    background: #999;
  }
}

.cancel-btn {
  text-align: center;
  font-size: 12px;
  margin-top: 16px;
}

.email {
  font-size: 12px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
