<template>
  <div>
    <div class="operation-holder"></div>
    <div class="operation">
      <div class="operation-left">
        <div class="operation-item">
          <i :class="`iconfont icon-like ${info.clickRecommend?'active icon-like_active':''}`"
             @click="toggleRecommend">
          </i>
          <div class="operation-badge" v-if="info.recommendAmount>0">
            {{ info.recommendAmount > 99 ? '99+' : info.recommendAmount }}
          </div>
        </div>

        <div class="operation-item">
          <i :class="`iconfont icon-collect ${info.clickCollect?'active icon-collect_active':''}`"
             @click="toggleCollect"></i>
          <div class="operation-badge" v-if="info.collectAmount>0">
            {{ info.collectAmount > 99 ? '99+' : info.collectAmount }}
          </div>
        </div>

        <div class="operation-item">
          <i class="iconfont icon-share" @click="showShare"></i>
          <div class="operation-badge" v-if="info.downloadAmount>0">
            {{ info.downloadAmount > 99 ? '99+' : info.downloadAmount }}
          </div>
        </div>
      </div>

      <div class="operation-right">
        <div class="unlock-btn has-unlock"
             v-if="!hasLogin && $route.query.shareUserId && (info.isMemberFree === 'Y' || info.downloadPrice === 0)"
             @click="onClickUnlock"> 点击下载(已解锁)
        </div>
        <div class="unlock-btn" v-else-if="!info.pay" @click="onClickUnlock">解锁下载
          <img src="@/assets/images/download.svg" alt="">
        </div>
        <div class="unlock-btn has-unlock" v-else-if="info.onlineOnly" @click="showDownloadModal">已解锁</div>
        <div class="unlock-btn has-unlock" v-else @click="showDownloadModal">点击下载(已解锁)</div>
      </div>


    </div>
    <van-share-sheet
      v-model="visibles.share"
      title="立即分享给好友"
      :options="shareOptions"
      @select="selectShare"
    >
      <template #description>
        <template v-if="info.downloadPrice === 0">
          <div>新用户通过你的分享🔗首次绑定手机号</div>
          <div>无额度限制即可免费下载这份方案</div>
        </template>
        <template v-else-if="info.isMemberFree==='Y'">
          <div>新用户通过你的分享🔗首次绑定手机号</div>
          <div>不用钱即可免费下载这份方案(￥{{ info.downloadPrice }})</div>
        </template>
        <template v-else>
          <div>新用户通过你的分享🔗首次绑定手机号</div>
          <div>双方都会获得💰{{
              yanzhiFilter(config.recommendAwardYanzhi)
            }}奖励严值噢(¥{{ moneyFilter(config.recommendAwardYanzhi / 10) }})~&nbsp; 邀请人数无上限✌️~
          </div>
        </template>
      </template>
    </van-share-sheet>
    <download-plan-modal :plan-id="info.id" ref="downloadPlanModel"
                         @verifyCode="$refs.unlockVerifyCodeDialog.show(vipInfo,info)"/>
    <plan-online-only ref="planOnlineOnly" @goVip="$emit('showVipPopup')"/>
    <free-case-unlock-dialog @showVipPopup="$emit('showVipPopup')" ref="freeCaseUnlockDialog" @success="unlockSuccess"
                             @verifyCode="$refs.unlockVerifyCodeDialog.show(vipInfo,info)"/>
    <member-free-unlock-dialog @showVipPopup="$emit('showVipPopup')" ref="memberFreeUnlockDialog"
                               @success="unlockSuccess" @verifyCode="$refs.unlockVerifyCodeDialog.show(vipInfo,info)"/>
    <yanzhi-unlock-dialog @showVipPopup="$emit('showVipPopup')" ref="yanzhiUnlockDialog" @success="unlockSuccess"
                          @verifyCode="$refs.unlockVerifyCodeDialog.show(vipInfo,info)" @share="visibles.share=true"/>
    <unlock-verify-code-dialog ref="unlockVerifyCodeDialog" @success="unlockSuccess"/>
  </div>
</template>


------------------ script ------------------
<script>
import {planV2Api} from "../../../api/v2/planV2Api";
import {taskV2Api} from "../../../api/v2/taskV2Api";
import {mapGetters} from "vuex";
import {copyToClipBoard, sleep, timeRandomId} from "../../../config/util";
import DownloadPlanModal from "./downloadPlanModal";
import {vipInfoMixin} from "../../../mixins/vipInfoMixin";
import FreeCaseUnlockDialog from "./freeCaseUnlockDialog";
import MemberFreeUnlockDialog from "./memberFreeUnlockDialog";
import YanzhiUnlockDialog from "./yanzhiUnlockDialog";
import UnlockVerifyCodeDialog from "./unlockVerifyCodeDialog";
import {taskFinishRecordV2Api} from "../../../api/v2/taskFinishRecordV2Api";
import {taskIdEnum} from "../../../config/enums";
import {wxUrlSchemeV2Api} from "../../../api/v2/wxUrlSchemeV2Api";
import {weAppSceneV2Api} from "../../../api/v2/weAppSceneV2Api";
import PlanOnlineOnly from "./planOnlineOnly.vue";

export default {
  components: {
    PlanOnlineOnly,
    UnlockVerifyCodeDialog,
    YanzhiUnlockDialog, MemberFreeUnlockDialog, FreeCaseUnlockDialog, DownloadPlanModal
  },
  mixins: [vipInfoMixin],
  props: {
    info: {
      type: Object,
      default: {}
    },
    page: {
      type: String,
      default: 'case'
    },
  },

  computed: {
    ...mapGetters(['userInfo'])
  },


  data() {
    return {
      visibles: {
        share: false
      },
      shareOptions: [
        {name: '💰复制链接', icon: 'link', code: 'copyLink'},
        {name: '💰分享海报', icon: 'poster', code: 'sharePost'},
        {name: '💰小程序分享', icon: 'wechat', code: 'wechat'},
      ],
      config: {
        recommendAwardYanzhi: 0
      },
    }
  },


  created() {
    this.loadRecommendAwardYanzhi();
  },


  methods: {

    showShare() {
      this.visibles.share = true
    },


    selectShare(option) {
      if (!this.hasLogin) {
        return this.$store.commit('SHOW_LOGIN_DIALOG')
      }
      const {code} = option;
      if (code === 'copyLink') {
        this.copy();
      } else if (code === 'sharePost') {
        return this.$router.push({name: 'caseDetailSharePost', params: {id: this.info.id}})
      } else if (code === 'wechat') {
        return wxUrlSchemeV2Api.getWxUrlScheme(
          'pages/home/caseDetail/index',
          `id=${this.$route.params.id}&showShare=true`,
          '分享详情页-' + this.info.planTitle
        ).then(res => {
          window.location.href = res
        })
      }

      this.visibles.share = false;
    },


    //复制
    copy() {
      let url = window.location.href;
      if (this.userInfo.id) {
        if (window.location.href.indexOf('?') > -1) {
          url += '&shareUserId=' + this.userInfo.id;
        } else {
          url += '?shareUserId=' + this.userInfo.id;
        }
      }
      try {
        copyToClipBoard(this.info.planTitle + '  ' + url);
        this.$toast.success('复制链接成功');
      } catch (e) {
        this.$toast.error('复制失败');
      }
    },

    /**
     * 加载首次奖励严值
     */
    async loadRecommendAwardYanzhi() {
      const res = await taskV2Api.findById(6);
      this.config.recommendAwardYanzhi = res.value;
    },

    // 切换收藏
    async toggleCollect() {
      if (!Object.keys(this.userInfo).length) {
        return this.$store.commit('SHOW_LOGIN_DIALOG')
      }
      try {
        await planV2Api.toggleCollect({planId: this.info.id});
        if (!this.info.clickCollect) {
          await sleep(500)
          this.$myNotify.show({
            type: 'success',
            text: '收藏成功',
            rightText: '去看看',
            onClick: () => {
              this.$router.push('/collection?refresh')
            }
          })
        } else {
          await sleep(500)
          this.$myNotify.show({
            type: 'info',
            text: '取消收藏成功',
          })
        }
        this.$emit('operation', 'collect')
        taskFinishRecordV2Api.notifyByTaskId(taskIdEnum.FINISH_COLLECT).then()
      } catch (e) {
        this.$toast.error(e.message || '操作失败');
      }
    },


    // 切换推荐
    async toggleRecommend() {
      if (!Object.keys(this.userInfo).length) {
        return this.$store.commit('SHOW_LOGIN_DIALOG')
      }
      try {
        await planV2Api.toggleRecommend({planId: this.info.id});
        if (!this.info.clickRecommend) {
          this.$toast.success('已成功推荐')
        } else {
          this.$toast.success('已取消推荐')
        }
        taskFinishRecordV2Api.notifyByTaskId(taskIdEnum.FINISH_RECOMMEND).then()
        this.$emit('operation', 'recommend')
      } catch (e) {
        this.$toast.error(e.message || '操作失败');
      }
    },


    async showDownloadModal() {
      // 判断直接跳小程序的逻辑
      if (this.$route.query.unlockAction === 'wxApp' || this.$route.query.from === 'xhs') {
        const scene = timeRandomId()
        weAppSceneV2Api.generate({
          id: scene,
          page: `/pages/home/caseDetail/index`,
          query: `id=${this.info.id}`,
          navType: 'redirectTo',
          shareUserId: this.$route.query.shareUserId
        })
        this.$loading('正打开小程序')
        return setTimeout(() => {
          this.$loading.close()
          return window.location.href = `https://www.001ppt.com/urlScheme?remark=小程序方案详情页${this.info.id}&path=pages/home/scanEnter/index&query=scene=${scene}`
        }, 1500)
      }

      if (!this.info.onlineOnly) {
        this.$refs.downloadPlanModel.toggleDialog()
      } else {
        this.$refs.planOnlineOnly.show()
      }
    },


    // 点击解锁按钮
    onClickUnlock() {
      // 判断直接跳小程序的逻辑
      if (this.$route.query.unlockAction === 'wxApp' || this.$route.query.from === 'xhs') {
        const scene = timeRandomId()
        weAppSceneV2Api.generate({
          id: scene,
          page: `/pages/home/caseDetail/index`,
          query: `id=${this.info.id}`,
          navType: 'redirectTo',
          shareUserId: this.$route.query.shareUserId
        })
        this.$loading('正打开小程序')
        return setTimeout(() => {
          this.$loading.close()
          window.location.href = `https://www.001ppt.com/urlScheme?remark=小程序方案详情页${this.info.id}&path=pages/home/scanEnter/index&query=scene=${scene}`
        }, 1500)
      }

      if (!Object.keys(this.userInfo).length) {
        return this.$store.commit('SHOW_LOGIN_DIALOG');
      }
      // 免费方案
      if (this.info.downloadPrice === 0) {
        return this.$refs.freeCaseUnlockDialog.show(this.vipInfo, this.info)
      }
      // 会员免费
      else if (this.info.isMemberFree === 'Y') {
        return this.$refs.memberFreeUnlockDialog.show(this.vipInfo, this.info)
      }
      // 会员折扣
      else {
        return this.$refs.yanzhiUnlockDialog.show(this.vipInfo, this.info);
      }
    },


    // 解锁成功后
    unlockSuccess(e) {
      // this.$refs.downloadPlanModel.toggleDialog()
      this.$refs.unlockVerifyCodeDialog.hide()
      this.$refs.memberFreeUnlockDialog.hide()
      this.$refs.freeCaseUnlockDialog.hide()
      this.$refs.yanzhiUnlockDialog.hide()
      this.$emit('operation', 'unlock')
      if (!e.onlineOnly) {
        this.$refs.downloadPlanModel.toggleDialog()
      } else {
        this.$refs.planOnlineOnly.show()
      }
    },

  }

}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.operation-holder {
  height: 60px;
  width: 100vw;
}

.operation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 12px;
  position: fixed;
  bottom: 0;
  width: calc(100vw - 24px);
  background: #fff;
  z-index: 10;
}

.operation-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 81px;

  .iconfont {
    font-size: 19px;

    &.icon-collect {
      font-size: 21px;
    }

    &.icon-collect_active {
      font-size: 21px;
    }

    &.icon-share {
      position: relative;
      top: 1px;
    }

    &.active {
      color: #ee4a50;
    }
  }
}

.unlock-btn {
  padding: 8px 20px 8px 24px;
  background: #ee4a50;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 12px;

  &.has-unlock {
    background: #151D36;
  }

  img {
    height: 12px;
    width: 12px;
    margin-left: 4px;
  }
}

.operation-item {
  position: relative;
  margin-right: 16px;
}

.operation-badge {
  position: absolute;
  right: -16px;
  top: -16px;
  background: #e44c4c;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 12px;
  transform: scale(.6);
  font-weight: bolder;
}
</style>
