import {v2Request} from "./v2Request";

let findRankProm = null


export const searchRecordV2Api = {


  findRank() {
    if(!findRankProm) {
      findRankProm = v2Request.get('search-svr/searchRecord/findRank')
    }
    return findRankProm
  }

}
