<template>
  <div class="plan-detail-ref-collection">
    <div class="header">
      <img class="mai-sui" src="https://cdn.001ppt.cn/pc2/static/plan-detail/collection-title-mai_sui-left.svg" alt="">
      <div class="title-container">
        <div class="title">
          <div>本方案已入选</div>
          <div class="title-main">《{{ model.title }}》</div>
        </div>
        <div class="dynamic">
          <div class="dynamic-item">
            <div class="dynamic-value">{{fansNumFilter(model.planTotalPayCount,1000)}}</div>
            <img class="dynamic-icon" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/download-active.svg" alt="" v-if="model.pay">
            <img class="dynamic-icon" src="https://cdn.001ppt.cn/pc2/static/plan-detail/collection-download.png" alt="" v-else>
          </div>
          <div class="dynamic-item" @click.stop="toggleRecommend">
            <div class="dynamic-value">{{fansNumFilter(model.planTotalRecommendCount,1000)}}</div>
            <img class="dynamic-icon" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/thumb-active.svg" alt="" v-if="model.clickRecommend">
            <img class="dynamic-icon" src="https://cdn.001ppt.cn/pc2/static/plan-detail/collection-recommend.png" alt="" v-else>
          </div>
          <div class="dynamic-item" @click.stop="toggleCollect">
            <div class="dynamic-value">{{fansNumFilter(model.planTotalCollectCount,1000)}}</div>
            <img class="dynamic-icon" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/star-active.svg" alt="" v-if="model.clickCollect">
            <img class="dynamic-icon" src="https://cdn.001ppt.cn/pc2/static/plan-detail/collection-star.png" alt="" v-else>
          </div>
        </div>
      </div>
      <img class="mai-sui" src="https://cdn.001ppt.cn/pc2/static/plan-detail/collection-title-mai_sui-right.svg" alt="">
    </div>


    <div class="switch-btn">
      <div @click="nextPage">换一批</div>
      <i class="iconfont icon-change switch-btn-icon" style="margin-left: 4px"></i>
    </div>


    <div class="plans">
      <div class="plan" v-for="(item,index) in pageData.records" :key="index">
        <plan-card :detail="item" @handle-click="goPlanDetail(item)"/>
      </div>
    </div>


    <div class="fg">
      <img class="fg-img" src="https://cdn.001ppt.cn/pc2/static/plan-detail/collection-h5-fg.png" alt="">
      <div class="fg-btn" v-if="model.pay" @click.stop="$refs.operation.$refs.hasUnlockedModel.toggleDialog()">
        <div class="fg-btn-value">{{model.planNum}}份</div>
        点击下载(已解锁)
      </div>
      <div class="fg-btn" v-else style="background: #fbe8bf;color: #ee4a50" @click.stop="$refs.operation.onClickUnlock()">
        <div class="fg-btn-value fg-btn-vip">
          <template v-if="model.isMemberFree"><img :src="getVipIcon('4')" alt="">VIP免费</template>
          <template v-else><img :src="getVipIcon('5')" alt="">俱乐部免费</template>
        </div>
        打包下载本合集({{model.planNum}}份)
      </div>
    </div>

    <plan-list-operation style="width: 0;height: 0;" :info="model" page="group" ref="operation" @unlock="onUnlock"></plan-list-operation>

  </div>
</template>

<script>
import {planListV2Api} from "../../../../api/v2/planListV2Api";
import PlanCard from "../../../../components/planCard";
import {mapGetters} from "vuex";
import PlanListOperation from "../../components/planListOperation";
import {nav} from "../../../../config/nav";

export default {
  components: {PlanListOperation, PlanCard},
  props: ['collectionId'],
  computed:{
    ...mapGetters(['userInfo'])
  },
  watch: {
    collectionId: {
      immediate: true,
      handler() {
        if (this.collectionId) {
          this.loadModel(this.collectionId)
          this.loadPageData(1)
        }
      }
    }
  },


  data() {
    return {
      model: {},
      pageData: {
        current: 1,
        size: 3,
        total: 0,
        records: []
      }
    }
  },


  methods: {

    /**
     * 加载数据
     */
    async loadModel(collectionId) {
      this.model = await planListV2Api.findAppletDetailById(collectionId)
    },


    /**
     * 加载方案数据
     */
    async loadPageData(current, size) {
      current = current || this.pageData.current
      size = size || this.pageData.size
      const planListId = this.collectionId
      this.pageData = await planListV2Api.findPlanPageByPlanListId({
        current,
        size,
        pageSize: size,
        planListId
      })
    },


    // 下一页
    nextPage() {
      let current = this.pageData.current + 1
      if (current * this.pageData.size > this.pageData.total) {
        current = 1
      }
      this.loadPageData(current)
    },


    /**
     * 切换推荐状态
     */
    async toggleRecommend() {
      if (!Object.keys(this.userInfo).length) {
        await this.$store.dispatch('showLoginDialog');
      }
      planListV2Api.toggleRecommend({id: this.collectionId})
      this.model.clickRecommend = !this.model.clickRecommend
      if (this.model.clickRecommend) {
        this.$toast.success('推荐成功')
        this.model.planTotalRecommendCount += 1
      } else {
        this.$toast.info('取消推荐成功')
        this.model.planTotalRecommendCount -= 1
      }
    },


    /**
     * 切换点赞状态
     */
    async toggleCollect() {
      if (!Object.keys(this.userInfo).length) {
        await this.$store.dispatch('showLoginDialog');
      }
      planListV2Api.toggleCollect({id: this.collectionId})
      this.model.clickCollect = !this.model.clickCollect
      if (this.model.clickCollect) {
        this.$myNotify.show({
          type: 'success',
          text: '收藏成功',
          rightText:'去看看',
          onClick:()=>{
            this.$router.push('/collection?refresh')
          }
        })
        this.model.planTotalCollectCount += 1
      } else {
        this.$myNotify.show({
          type: 'info',
          text: '取消收藏成功',
        })
        this.model.planTotalCollectCount -= 1
      }
    },


    goPlanDetail(item) {
      nav.goPlanDetail(item.plan.id)
    },


    onUnlock(){
      this.model.pay = true
      this.$forceUpdate()
    },

  }
}
</script>

<style scoped lang="less">
.plan-detail-ref-collection {
  background: url("https://cdn.001ppt.cn/pc2/static/plan-detail/collection-h5-bg.png");
  width: calc(100vw - 16px);
  height: calc((100vw - 16px) * 446 / 358);
  background-size: 100%;
  position: relative;
  margin: 0 auto;
}

.plan-detail-ref-collection .header {
  display: flex;
  align-items: center;
  padding: 24px 46px 0 46px;
}

.plan-detail-ref-collection .mai-sui {
  width: 31px;
  height: 59px;
}

.plan-detail-ref-collection .title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-detail-ref-collection .title {
  font-weight: bolder;
  font-size: 15px;
  color: #74502c;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.plan-detail-ref-collection .title-main {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
}


.plan-detail-ref-collection .dynamic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-detail-ref-collection .dynamic-item {
  width: 50px;
  height: 24px;
  background: #fff;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 8px 0 8px;
  position: relative;
}

.plan-detail-ref-collection .dynamic-icon {
  width: 16px;
  height: 16px;
}

.plan-detail-ref-collection .dynamic-value{
  position: absolute;
  right: -16px;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: #74502c;
  border-radius: 100px;
  color: #fff;
  padding: 0 8px;
  transform: scale(.7);
}


.plan-detail-ref-collection .switch-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 20px 0 0;
  font-size: 12px;
  color: #74502c;
  font-weight: bolder;
  transform: scale(.95);
}

.plan-detail-ref-collection .switch-btn-icon {
  transform: scale(.9);
}

.plan-detail-ref-collection .plans {
  height: 292px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
}

.plan-detail-ref-collection .plan{
  margin-bottom: 8px;
}

.plan-detail-ref-collection .fg{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.plan-detail-ref-collection .fg-img{
  width: calc(100vw - 16px);
  height: calc((100vw - 16px) * 321 / 1080);
}

.plan-detail-ref-collection .fg-btn {
  background: #151D36;
  color: #fff;
  padding: 4px 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: bolder;
}

.plan-detail-ref-collection .fg-btn-value{
  position: absolute;
  top: -12px;
  right: -20px;
  padding: 2px 6px;
  background: #ee4a50;
  border: 1px solid #fff;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transform: scale(.7);
}

.plan-detail-ref-collection .fg-btn-vip{
  border: none;
  display: flex;
  align-items: center;
  background: #151D36;
}


.plan-detail-ref-collection .fg-btn-vip img{
  height: 12px;
  width: 12px;
  margin-right: 4px;
}

</style>
