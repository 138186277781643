<template>
  <model ref="model">
    <div>
      <div class="unlock-dialog-header">
        <div class="vip-badge" :class="`vip-badge-${userVip.vipRightId}`">
          <img :src="getVipIconNew(userVip.vipRightId)" alt="" style="height: 18px;width: 54px;border-radius: 100px">
        </div>
      </div>
      <div class="unlock-dialog">
        <div class="title" style="letter-spacing: 2px">已解锁文件完整版</div>
        <i class="iconfont icon-success success-icon"></i>
        <div class="ft-14 flex direction-column align-center justify-center">
          <div class="flex align-center justify-center">
            如需下载文件到本地 请
            <div style="color: #0e8ffb;padding-left: 2px" @click="goVip">升级会员</div>
          </div>
        </div>
      </div>
    </div>
  </model>
</template>

<script>
import Model from "../../../components/model/model";
import {mapGetters} from "vuex";

export default {
  components: {Model},
  computed: {
    ...mapGetters(['userVip'])
  },

  methods: {
    show() {
      this.$refs.model.show()
    },
    hide() {
      this.$refs.model.hide()
    },
    goVip() {
      this.hide()
      this.$emit('goVip')
    }
  }
}
</script>

<style lang="less" scoped>
@import "./unlock";

.success-icon {
  display: block;
  width: 100px;
  margin: 0 auto 20px auto;
  height: 100px;
  font-size: 100px;
  text-align: center;
  border-radius: 100px;
  padding: 16px 0;
}
</style>
