<template>
  <div class="auto-width-label">
    <div v-for="(letter,index) in label">{{letter}}</div>
  </div>
</template>

<script>
export default {
  props:['label']
}
</script>

<style scoped>
.auto-width-label{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
