<template>
  <div class="plan-detail-info">
    <div class="recommend" style="padding-top: 24px">
      <div class="recommend-btn">推荐语</div>
      <div class="recommend-content">{{ info.recommend }}</div>
    </div>


    <div class="panel category">
      {{ subTitle }}
    </div>


    <div class="panel" v-if="infoVisibles.allProps">
      <div class="prop" v-if="infoVisibles.brank">
        <auto-width-label label="品牌方" class="label"/>
        <div class="value">{{ info.brank || '暂未确定' }}</div>
      </div>
      <div class="prop" v-if="infoVisibles.brank1">
        <auto-width-label label="联合主办" class="label"/>
        <div class="value">{{ info.brank1 || '暂未确定' }}</div>
      </div>
      <div class="prop" v-if="infoVisibles.projectTime">
        <auto-width-label label="项目周期" class="label"/>
        <div class="value">{{ info.projectStartTime }} ~ {{ info.projectEndTime }}</div>
      </div>
      <div class="prop" v-if="infoVisibles.place">
        <auto-width-label label="具体场地" class="label"/>
        <div class="value">{{ info.place || '暂未确定' }}</div>
      </div>
    </div>

    <div class="panel" v-if="infoVisibles.refPlanLists">
      <div class="prop">
        <auto-width-label label="入选合集" class="label"/>
<!--        <div class="gray" style="flex-shrink: 0">入选合集</div>-->
        <div class="plan-lists value">
          <div @click="$router.push(`/casePlanDetail/${item.id}`)" :key="item.id" v-for="item in refPlanLists">{{item.title}}</div>
        </div>
      </div>
    </div>


    <div class="panel">
      <div class="prop" @click="copyPlanId">
        <div class="gray"><auto-width-label label="方案编号" class="label"/></div>
        <div class="value">{{ info.id }} <van-icon style="margin-left: 4px" name="orders-o"/></div>
      </div>
      <div class="prop">
        <div class="gray"><auto-width-label label="版权声明" class="label"/></div>
        <div class="value">{{ info.authTypeName }}</div>
      </div>
      <div class="prop">
        <div class="gray"><auto-width-label label="支付提示" class="label"/></div>
        <div class="value">以电子文档交付 (不支持退款)</div>
      </div>
    </div>


    <div class="panel" style="border-bottom: none" v-if="info.planTagName&&info.planTagName.length">
      <div class="prop">
        <auto-width-label label="相关搜索" class="label"/>
        <div class="value tags" style="padding-left: 12px">
          <div class="tag" @click="$router.push('/home?ts=' + Date.now() + '&keyword=' + item)" :key="item" v-for="item in info.planTagName.split(',')">{{item}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoWidthLabel from "../../../../components/autoWidthLabel";
import {copyToClipBoard} from "../../../../config/util";

export default {
  components: {AutoWidthLabel},
  props: ['info', 'visibleOption', 'refPlanLists'],

  computed: {
    subTitle() {
      if (!this.info || !Object.keys(this.info).length) {
        return '';
      }
      const {
        industryTypeName,
        activityTypeName,
        activityTypeName2,
        planTypeName,
        enjoyAmount,
        projectBudget,
        placeTypeName
      } = this.info;
      const activityTypeNameStr = [activityTypeName, activityTypeName2].filter(n => n && n.length).join(',');
      return [ industryTypeName,activityTypeNameStr, planTypeName, enjoyAmount, projectBudget, placeTypeName]
        .filter(n => n && n.length && n !== '不限')
        .join(' | ')
    },


    infoVisibles() {
      const visibles = {}
      const {info, visibleOption, refPlanLists} = this
      visibles.brank = info.brank && visibleOption.brankVisible
      visibles.brank1 = info.brank1 && visibleOption.brank1Visible
      visibles.projectTime = info.projectStartTime && info.projectEndTime && visibleOption.procircleVisible
      visibles.place = info.place && visibleOption.placeVisible
      visibles.refPlanLists = refPlanLists && refPlanLists.length
      visibles.allProps = visibles.brank || visibles.brank1 || visibles.projectTime || visibles.place
      return visibles
    }
  },


  methods:{
    copyPlanId(){
      copyToClipBoard(this.info.id)
      this.$toast.success('复制成功')
    }
  }

}
</script>

<style scoped lang="less">
.plan-detail-info {
  background: #fff;
  padding: 0 27px;
  font-size: 12px;
}

.panel {
  border-bottom: 1px solid #e9e9e9;
  padding: 24px 0;
}

.recommend {
  display: flex;
  align-items: center;
}

.recommend-btn {
  background: #151D36;
  color: #fff;
  padding: 4px 8px;
  border-radius: 100px;
  flex-shrink: 0;
  width: 60px;
  text-align: center;
}

.recommend-content {
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.prop {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.prop .label {
  color: #999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  width: 50px;
  flex-shrink: 0;
  flex-shrink: 0;
}

.prop .value {
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.plan-lists{
  color: #02A9F1;
  flex-direction: column;
  align-items: flex-start !important;
}

.tags{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tag{
  padding: 4px 8px;
  background: #f2f2f2;
  color: #000;
  border-radius: 100px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 4px;
  margin-top: 4px;
}

</style>
