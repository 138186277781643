<template>
  <div :class="'plan-detail-tab ' + (white?'white':'')">
    <div @click="switchTab('img-list')" class="tab-item" :class="{active:tab==='img-list'}">方案详情</div>
    <div @click="switchTab('info')" class="tab-item" :class="{active:tab==='info'}">基本信息</div>
    <div @click="switchTab('todayRank')" class="tab-item" :class="{active:tab==='todayRank'}">今日热门</div>
    <div @click="switchTab('recommend')" class="tab-item" :class="{active:tab==='recommend'}">其他推荐</div>
  </div>
</template>

<script>
export default {
  props:['tab','white'],
  methods:{
    switchTab(tab){
      this.$emit('change',tab)
    }
  }
}
</script>

<style scoped lang="less">
.plan-detail-tab{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.plan-detail-tab.white{
  background: #fff;
}

.plan-detail-tab .tab-item{
  font-size: 12px;
  color: #999;
  padding: 0 12px;
}

.plan-detail-tab .tab-item.active{
 color: #151D36;
  font-weight: bolder;
}
</style>
