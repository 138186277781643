<template>
  <div class="plan-detail" @scroll="onScroll" ref="planDetail">
    <home-plans-search :header-height="styles.headerHeight" :query="searchQuery" ref="homePlanSearch"
                       @addKeyword="onSearch" @setKeyword="onSearch" @searchBrank="searchBrank"/>
    <plan-detail-header @collect="$refs.operation.toggleCollect()" @share="showShare"
                        @search="$refs.homePlanSearch.toggleSearch()" :info="info" :loading="loading"
                        @headerHeight="styles.headerHeight=$event"/>
    <plan-detail-be-share-guide :info="info"/>
    <plan-detail-base-info :info="info" :loading="loading" :visible-option="visibleOption"/>

    <van-sticky :offset-top="styles.headerHeight">
      <plan-detail-tab :white="whiteTab" :tab="tab" @change="onChangeTab" id="planDetailTab"/>
    </van-sticky>

    <plan-detail-img-list :loading="loading" :info="info" :images="images" id="img-list"/>
    <plan-detail-ref-collection v-if="refPlanLists && refPlanLists.length" :collection-id="refPlanLists[0].id"/>
    <plan-detail-info :info="info" :visibleOption="visibleOption" :ref-plan-lists="refPlanLists" id="info"/>
    <plan-detail-today-rank id="todayRank"/>
    <plan-detail-recommend :info="info" :ref-plans="refPlans" @nextRefPlanPage="loadRefPlans" id="recommend"/>


    <div class="copyright">
      <div>© 方案版权归上传用户解释</div>
      <div @click="$router.push('/about/copyright')" class="copyright-link">版权声明 <van-icon name="arrow"/></div>
    </div>

    <div class="pager" v-if="$store.state.caseDetailPagerVisible">
      <div class="pager-close" @click="$store.commit('HIDE_DETAIL_PAGER')">
        <van-icon name="cross"/>
      </div>
      <div v-if="!pager.scrolling" class="pager" @click="$refs.planDetail.scrollTo({top:0,behavior:'smooth'})">
        <van-icon name="back-top"/>
      </div>
      <template v-else>
        <div class="pager-item curr">{{ pager.curr }}</div>
        <div class="pager-item">{{ info.projPlanPicResources.length }}</div>
      </template>
    </div>
    <operation ref="operation" :info="info" @operation="operation" page="case"
               @showVipPopup="$refs.planDetailVipPopup.show()"></operation>
    <plan-detail-vip-popup ref="planDetailVipPopup" :plan-id="info.id"/>
  </div>
</template>

<script>
import {closeGlobalLoading, showGlobalLoading, sleep} from "../../../config/util";
import PlanDetailHeader from "./components/planDetailHeader";
import PlanDetailBaseInfo from "./components/planDetaiBaselInfo";
import {scheme, user} from '@/api'
import {planV2Api} from "../../../api/v2/planV2Api";
import PlanDetailTab from "./components/planDetailTab";
import PlanDetailImgList from "./components/planDetailImgList";
import PlanDetailInfo from "./components/planDetailInfo";
import PlanDetailRecommend from "./components/planDetailRecommend";
import {homeSearchV2Api} from "../../../api/v2/homeSearchV2Api";
import Operation from "../components/operation";
import HomePlansSearch from "../../home/components/homePlansSearch";
import PlanDetailVipPopup from "./components/planDetailVipPopup";
import PlanDetailTodayRank from "./components/planDetailTodayRank";
import PlanDetailBeShareGuide from "./components/planDetailBeShareGuide";
import PlanDetailRefCollection from "./components/planDetailRefCollection";


export default {
  components: {
    PlanDetailRefCollection,
    PlanDetailBeShareGuide,
    PlanDetailTodayRank,
    PlanDetailVipPopup,
    HomePlansSearch,
    Operation,
    PlanDetailRecommend,
    PlanDetailInfo,
    PlanDetailImgList,
    PlanDetailTab,
    PlanDetailBaseInfo,
    PlanDetailHeader
  },
  data() {
    return {
      pager: {
        scrolling: false,
        curr: 1,
      },
      searchQuery: {},
      refPlanLists: [],
      clickChangeTab: false,
      refPlans: {
        current: 1,
        records: []
      },
      loading: false,
      info: {},
      images: [],
      whiteTab: false,
      styles: {
        headerHeight: 0
      },
      tab: 'img-list',
      visibleOption: {
        browseTimesVisible: false,
        recommendTimesVisible: false,
        collectTimesVisible: false,
        downloadTimesVisible: false,
        placeVisible: false,
        createTimeVisible: false,
        lookFreeVisible: false,
        fileExtVisible: false,
        fileSizeVisible: false,
        brankVisible: false,
        brank1Visible: false,
        procircleVisible: false
      },
    }
  },

  watch: {
    '$route.params.id'() {
      this.initPlanData()
    }
  },

  mounted() {
    closeGlobalLoading()
    this.initPlanData()
    this.loadVisibleOption()
  },


  methods: {

    async initPlanData() {
      // showGlobalLoading(true)
      this.loading = true
      const isOnline = await planV2Api.findPlanIsOnline(this.$route.params.id)
      if(!isOnline){
        this.$toast.info('方案不存在或已下架')
        return this.$router.push('/')
      }
      const loadInfoProm = this.loadInfo()
      const sleepProm = sleep(400)
      this.loadRefPlanLists()
      this.loadRefPlans(true)
      await Promise.all([loadInfoProm, sleepProm])
      // closeGlobalLoading()

      let $planDetail = null
      while(!$planDetail){
        await sleep(200)
        $planDetail = document.querySelector('.plan-detail')
        if($planDetail){
          $planDetail.scrollTo({top: 0, behavior: 'smooth'})
        }
      }
    },


    showShare() {
      this.$refs.operation.showShare()
    },


    async onChangeTab(tab) {
      this.tab = tab
      await sleep(100)
      switch (tab) {
        case 'img-list':
        case 'recommend':
        case 'todayRank':
        case 'info':
          if (!this.clickChangeTab) {
            this.clickChangeTab = true
            setTimeout(() => {
              this.clickChangeTab = false
            }, 3000)
          }
          const $tabItem = document.querySelector(`#${tab}`)
          const $tabRect = document.querySelector('#planDetailTab').getBoundingClientRect()
          document.querySelector('.plan-detail').scrollTo({
            top: tab === 'img-list' ? 0 : $tabItem.offsetTop - this.styles.headerHeight - $tabRect.height,
            behavior: 'smooth'
          })
          break
      }
    },


    async loadRefPlans(refresh) {
      this.refPlans = await homeSearchV2Api.findPlanRef(
        this.$route.params.id,
        refresh ? 1 : this.refPlans.current + 1,
        9
      )
    },


    async loadInfo() {
      try {
        this.loading = true
        const data = await planV2Api.findDetailByIdForApplet(this.$route.params.id)
        if (!data.id) {
          this.$toast.info('该方案不存在或已下架')
          await sleep(2000)
          if (!this.$route.meta.$from) {
            this.$router.back()
          } else {
            this.$router.replace('/')
          }
          return
        }

        this.info = data
        let temp = data.pay ? data.projPlanPicResources.length : data.supportLookFree;
        this.info = data;

        const images = [];
        for (let i = 0; i < temp && i<data.projPlanPicResources.length; i++) {
          images.push(data.projPlanPicResources[i]);
        }
        this.images = images
      } finally {
        this.loading = false
      }
    },


    async loadRefPlanLists() {
      const planId = this.$route.params.id;
      this.refPlanLists = await planV2Api.findRefPlanLists(planId);
    },


    async loadVisibleOption() {
      this.visibleOption = await planV2Api.findVisibleOption();
    },


    onSearch(keyword) {
      this.$router.push('/home?ts=' + Date.now() + '&keyword=' + keyword)
      this.$refs.homePlanSearch.hide()
    },


    searchBrank(brank){
      this.$router.push('/home?ts=' + Date.now() + '&brank=' + brank)
      this.$refs.homePlanSearch.hide()
    },


    onScroll() {
      this.computePager()
      const $planImgListRect = document.querySelector('#img-list').getBoundingClientRect()
      const $planDetailTabRect = document.querySelector('#planDetailTab').getBoundingClientRect()
      const $headerRect = document.querySelector('.plan-detail-header').getBoundingClientRect()
      if (!this.whiteTab && $planDetailTabRect.top >= $planImgListRect.top - 30) {
        this.whiteTab = true
      } else if (this.whiteTab && $planDetailTabRect.bottom < $planImgListRect.top) {
        this.whiteTab = false
      }

      if (this.clickChangeTab) {
        return
      }

      const $recommendRect = document.querySelector('#recommend').getBoundingClientRect()
      const $todayRankRect = document.querySelector('#todayRank').getBoundingClientRect()
      const $infoRect = document.querySelector('#info').getBoundingClientRect()
      const $tabRect = document.querySelector('#planDetailTab').getBoundingClientRect()

      let tab = 'img-list'
      if ($infoRect.top <= $tabRect.bottom + 10) {
        tab = 'info'
      }
      if ($todayRankRect.top <= $tabRect.bottom + 10) {
        tab = 'todayRank'
      }
      if ($recommendRect.top <= $tabRect.bottom + 10) {
        tab = 'recommend'
      }
      if (tab !== this.tab) {
        this.tab = tab
      }

    },


    computePager() {
      if (!this.pager.scrolling) {
        this.pager.scrolling = true
        setTimeout(() => {
          this.pager.scrolling = false
        }, 2000)
      }

      const imgs = document.querySelectorAll('.plan-img')
      const top = document.querySelector('.plan-detail-tab').getBoundingClientRect().bottom
      let curr = 1
      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i]
        if (img.getBoundingClientRect().top < top) {
          curr = i + 1
        } else {
          break
        }
      }
      this.pager.curr = Math.min(curr, this.info.projPlanPicResources.length)
    },


    operation(type) {
      switch (type) {
        case 'collect':
          this.info.clickCollect = !this.info.clickCollect
          this.info.collectAmount += this.info.clickCollect ? 1 : -1
          break
        case 'recommend':
          this.info.clickRecommend = !this.info.clickRecommend
          this.info.recommendAmount += this.info.clickRecommend ? 1 : -1
          break
        case 'unlock':
          this.info.pay = true
          this.loadInfo()
          break
      }
    }
  }

}
</script>

<style scoped lang="less">
.plan-detail {
  background: var(--page-color);
  height: 100vh;
  overflow-y: auto;
}


.copyright {
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #999;
  transform: scale(.8);
  line-height: 22px;
}

.copyright-link {
  font-size: 12px;
  color: #02A9F1;
}

.pager {
  position: fixed;
  right: 10px;
  bottom: 110px;
  height: 45px;
  width: 45px;
  border-radius: 1000px;
  background: #fff;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  z-index: 1000;
}

.pager-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
}

.pager-item.curr {
  border-bottom: 1px solid #e9e9e9;
}

.pager-close {
  position: absolute;
  right: -4px;
  top: -4px;
  color: #fff;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  z-index: 1002;
  border-radius: 100px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: .3;
    border-radius: 100px;
  }
}

</style>
