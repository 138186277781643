import {v2Request} from "./v2Request";


export const weAppSceneV2Api = {

  async findById(id) {
    return v2Request.get(`weAppScene/findById?id=${id}`);
  },

  generate(param) {
    return v2Request.post(`weAppScene/generate`,param)
  }
};
