var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"recommend-header",staticStyle:{"padding":"12px 27px 12px 27px"}},[_c('div',{staticClass:"title"},[_vm._v("今日热门")]),_c('div',{staticClass:"switch-btn",on:{"click":function($event){return _vm.nextTodayRankPage(null)}}},[_c('i',{staticClass:"iconfont icon-change switch-btn-icon",staticStyle:{"margin-right":"4px"}}),_vm._v(" 换一批 ")])]),_vm._l((_vm.todayRank),function(item,index){return _c('div',{staticStyle:{"position":"relative"}},[_c('rank-item',{attrs:{"item":{
        planId: item.plan.id,
        planCover: item.plan.cover,
        planTitle: item.plan.planTitle,
        createAvatar: item.creator.avatar,
        createUsername: item.creator.nickName,
        rankIndex: item.rankIndex
      }}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }