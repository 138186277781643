<template>
  <div class="">
    <div class="recommend-header" style="padding: 12px 27px 12px 27px">
      <div class="title">猜你在找</div>
      <div class="switch-btn" @click="nextRefPlanPage">
        <i class="iconfont icon-change switch-btn-icon"  style="margin-right: 4px"></i>
        换一批
      </div>
    </div>
    <template v-if="refPlans.records  && refPlans.records.length">
      <rank-item v-for="item in refPlans.records" :item="{
        planId: item.plan.id,
        planCover: item.plan.cover,
        planTitle: item.plan.planTitle,
        createAvatar: item.creator.avatar,
        createUsername: item.creator.nickName,
      }" :hide-index="true"/>
    </template>
    <template v-else>
      <div class="skeleton-block"
           style="width: calc(100vw - 27px);height: calc( (100vw - 27px) * 9 / 16 );margin: 12px auto 0 auto;border-radius: 6px"></div>
    </template>
  </div>
</template>

<script>
import vCase from '@/components/case.vue'
import {nav} from "../../../../config/nav";
import PlanCard from "../../../../components/planCard";
import RankItem from "../../../home/components/rankItem";


export default {
  props: ['info', 'refPlans'],
  components: {RankItem, PlanCard, vCase},

  methods: {
    nextRefPlanPage() {
      this.$emit('nextRefPlanPage')
    },

    goPlanDetail(item){
      nav.goPlanDetail(item.plan.id)
    }
  }
}
</script>

<style scoped lang="less">
@import "planDetailRefs.css";

</style>
