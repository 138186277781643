<template>
  <div>
    <div class="plan-detail-header-holder"></div>
    <div class="plan-detail-header" ref="header">
      <i class="iconfont icon-arrow icon back" @click="$router.back()"></i>
      <i class="iconfont icon-home icon" @click="$router.push('/home')"></i>
      <div class="search" @click="$emit('search')">
        <div class="placeholder">快速找到好资料</div>
        <i class="iconfont icon-search"></i>
      </div>
      <div class="right">
        <div class="share" @click="inviteFriendSee">
          <template v-if="loading">
            <div style="height: 25px;width: 60px;border-radius: 100px" class="skeleton-block"></div>
          </template>
          <template v-else-if="info.isMemberFree !== 'Y' && info.downloadPrice > 0">
            🎁免费下载
          </template>
          <template v-else>
            🧧请朋友看
          </template>
        </div>
        <van-image @click="visibles.more=true" class="more"
                   src="https://cdn.001ppt.cn/h5/static/image/more-vertical.svg"/>

        <van-action-sheet v-model="visibles.more" :actions="actions.more" @select="onSelect"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['info', 'loading'],
  computed:{
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      visibles: {
        more: false
      },
      actions: {
        more: [
          {name: '回到首页', value: 'back-home'},
          {name: '我的解锁', value: 'my-unlock'},
          {name: '收藏这份', value: 'collect'},
        ]
      }
    }
  },
  mounted() {
    this.$emit('headerHeight', this.$refs.header.getBoundingClientRect().height)
  },

  methods: {
    onSelect(item) {
      switch (item.value) {
        case 'back-home':
          this.$router.push('/')
          break
        case 'collect':
          if(!this.info.clickCollect){
            this.$emit('collect')
          }
          break
        case 'my-unlock':
          if(Object.keys(this.userInfo) < 0){
            return this.$store.commit('SHOW_LOGIN_DIALOG')
          }
          this.$router.push('/unlock')
      }
      this.visibles.more = false
    },


    inviteFriendSee(){
      this.$store.commit('buryPoint/PUSH_BURY_POINTS',[{handle: 1}])
      this.$emit('share')
    }
  }
}
</script>


<style scoped lang="less">
.plan-detail-header {
  height: 40px;
  padding: 0 8px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

.plan-detail-header-holder {
  height: 40px;
  width: 100vw;
}

.plan-detail-header .icon {
  height: 40px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.plan-detail-header .icon.back {
  transform: rotate(180deg);
  padding-right: 0;
  padding-left: 12px;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 25px;
  background: var(--page-color);
  border-radius: 100px;
  flex-grow: 1;
  color: #999;
  font-size: 12px;
}

.plan-detail-header .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan-detail-header .right .share {
  padding: 0 8px 0 12px;
  font-size: 12px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  overflow: hidden;
}

.more {
  height: 16px;
  width: 20px;
}

</style>
