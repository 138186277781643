import {v2Request} from "./v2Request";


export const wxUrlSchemeV2Api = {

  async getWxUrlScheme(path, query, remark) {
    return v2Request.post(`/wxUrlScheme/getWxUrlScheme`, {wxAppPath: path, query, remark})
  }

}
