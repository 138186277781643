<template>
<model ref="model">
  <div class="unlock-dialog">
    <div class="title">防盗刷验证</div>
    <div class="desc">解锁过于频繁，本次下载请输入验证码</div>
    <div class="verify-code">
      <img @click="refreshCaptcha" class="img" :src="imgBase64" alt="">
      <input maxlength="6" type="text" v-model="verifyCode">
    </div>
    <div class="confirm-btn" @click="unlock">确认解锁下载</div>
  </div>
</model>
</template>


----------------------- script -----------------------
<script>
import Model from "../../../components/model/model";
import {unlockMixin} from "./unlockMixin";
import {planV2Api} from "../../../api/v2/planV2Api";

export default {
  components: {Model},
  mixins: [unlockMixin],

  data() {
    return {
      verifyCode: '',
      imgBase64: ''
    }
  },


  methods: {
    async show(vipInfo, plan) {
      await this.$store.dispatch('getWallet');
      this.vipInfo = vipInfo;
      this.plan = plan;
      this.$refs.model.toggleDialog();
      this.refreshCaptcha().then()
    },


    async refreshCaptcha(){
      this.imgBase64 = await planV2Api.captcha()
    }

  }

}

</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "./unlock";

.verify-code{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .img{
    width: 200px;
    height: 60px;
  }

  input{
    height: 30px;
    width: 200px;
    text-align: center;
    font-size: 14px;
    margin-top: 16px;
    border: 1px solid #E9E9E9;
  }
}
</style>
