import {v2Request} from "./v2Request";


export const brandV2Api = {

  randomGet(){
    return v2Request.get('/brand/randomGet')
  }

}
