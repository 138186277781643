<template>
  <model ref="model">
    <div class="unlock-dialog-header">
      <div class="vip-badge" :class="`vip-badge-${userVip.vipRightId}`" v-if="userVip.freePlanInfinity">
        <img :src="getVipIcon(userVip.vipRightId)">
        <div>会员无限</div>
      </div>
    </div>

    <div class="unlock-dialog">
      <div class="title">
        <template v-if="userVip.freePlanInfinity">无限解锁收入囊中</template>
        <template v-else-if="vipInfo.remainFreeCase>0">
          {{$store.state.isFreeCaseUnitDay?'今日':'免费'}}方案剩余额度
          <div class="red" style="margin-left: 8px">{{ vipInfo.remainFreeCase }}</div>
        </template>
        <template v-else>{{$store.state.isFreeCaseUnitDay?'今日':'免费'}}方案额度已用完</template>
      </div>

<!--      <bind-email @confirm="unlock" :disabled="vipInfo.vipRightId!=='5'&&vipInfo.vipRightId!=='4' && vipInfo.vipRightId !=='2' &&vipInfo.remainFreeCase<=0"/>-->
      <div class="confirm-btn" :class="{disabled: !userVip.freePlanInfinity &&vipInfo.remainFreeCase<=0}" @click="unlock">确认解锁下载</div>
      <template v-if="!userVip.freePlanInifity && userVip.remainFreeCase<=0">
        <div class="extra" v-if="vipInfo.remainFreeCase>0">*确认解锁后，{{$store.state.isFreeCaseUnitDay?'今日':'免费'}}剩余份数将为 {{ vipInfo.remainFreeCase - 1 }}</div>
        <div class="link" @click="showVipPopup">
          <img src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg">无限解锁了解一下 <van-icon name="arrow" />
        </div>

      </template>
    </div>
  </model>
</template>


------------------ script ------------------
<script>
import Model from "../../../components/model/model";
import BindEmail from "./bindEmail";
import {unlockMixin} from "./unlockMixin";
import {user} from "../../../api";

export default {
  computed: {
    user() {
      return user
    }
  },
  components: {BindEmail, Model},
  mixins: [unlockMixin],
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "./unlock";
</style>
